@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.btn {
  @apply h-11 px-8 bg-gray-800 rounded  hover:bg-gray-700 outline-none flex items-center justify-center  cursor-pointer;
}

.section-heading {
  @apply after:content-[''] after:flex after:justify-center after:mt-[0.5rem] after:w-[4rem] after:mx-auto after:h-[0.2rem] after:bg-red;
}

@keyframes blink {
  0% {
    color: #fff; /* Initial color */
  }

  50% {
    /* stylelint-disable-next-line csstree/validator */
    color: hwb(147 0% 0% / 0.834); /* Blinking color */
  }

  100% {
    color: #fff; /* Reset to initial color */
  }
}

.blink-text {
  white-space: nowrap; /* Ensures text stays on one line */
  animation: blink 1s infinite; /* Adjust the timing as needed */
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 290px; /* Adjust the width as needed */
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 50; /* Ensure it is above other content */
}

.nav-visible {
  transform: translateX(0);
}

.text-gray-100 {
  color: #f7fafc;
}

.text-2xl {
  font-size: 1.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.fixed {
  position: fixed;
}

.top-20px {
  top: 20px;
}

.left-20px {
  left: 20px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px !important;
  background-color: #f63;
  padding: 6px 10px;
  border-radius: 100%;
  color: #f7fafc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  opacity: 80%;
}

/* Change background color on hover */
.swiper-button-next:hover::after,
.swiper-button-prev:hover::after {
  background-color: #ff8f1f;
}

/* FacebookLivePlugin.css */
.fb-video-custom {
  width: 300px;
  height: 306px;
  max-width: 100%; /* Ensure responsiveness */
}

.swiper-pagination-bullet-active {
  background-color: #ff8f1f !important;
}

.swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background-color: white;
}
